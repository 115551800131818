import React, { useMemo, useEffect, Component } from 'react'
import { Button, Card } from 'react-bootstrap';
import objectPath from "object-path";
import { useHtmlClassService } from "../../_metronic/layout";
import { KTUtil } from "../../_metronic/_assets/js/components/util";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import ApexCharts from "apexcharts";
import SVG from 'react-inlinesvg'
import { shallowEqual, useSelector } from 'react-redux';

const PieChartProgressCard = (props) => {
    const uiService = useHtmlClassService();

    const { selectedLanguage } = useSelector(
        (state) => ({ selectedLanguage: state.ward.selectedLanguage }),
        shallowEqual
    );
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
            colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightPrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.light.primary"
            ),
            colorsThemeBasePrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.base.primary"
            ),
            colorsThemeBaseWarning: objectPath.get(
                uiService.config,
                "js.colors.theme.base.warning"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);
    useEffect(() => {
        if (props.data.labelDesc) {
            const element = document.getElementById(props.section + props.data.id);
            if (!element) {
                return;
            }
            const height = '290px';
            const options = getChartOptions(layoutProps, height);

            const chart = new ApexCharts(element, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };

        }
    }, [props]);

    function getChartOptions(layoutProps, height) {
        const horizontalMr = 10
        const horizontalMrSmall = props.data.data.length * 5

        const options = {
            series: props.data.data,
            chart: {
                height: height,
                type: 'donut',
            }, legend: {
                position: 'bottom',
                fontSize: '17px',
                fontWeight: 500,
                markers: {
                    width: 20,
                    height: 20,
                    radius: 5,
                },
                itemMargin: {
                    horizontal: horizontalMr,
                    vertical: 5
                },
                formatter: function (seriesName, opts) {
                    return [`<div class="d-flex flex-column"><div>${props.data.labels[opts.seriesIndex]}</div><div class="text-dark-65 font-size-lg">${seriesName}</div></div>`]
                }
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '90%',
                        margin: 0,
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                showAlways: false,
                                label: selectedLanguage == "nepali" ? 'जम्मा: ' : "Total: ",
                                fontSize: '18px',
                                fontFamily: 'Helvetica, Arial, "sans-serif" !important',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0)
                                }
                            }
                        },
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 991,
                    options: {
                        legend: {
                            position: 'bottom',
                            fontSize: '14px',
                            fontWeight: 500,
                            markers: {
                                width: 15,
                                height: 15,
                                radius: 3,
                            },
                            itemMargin: {
                                horizontal: horizontalMrSmall,
                                vertical: 5
                            },
                            formatter: function (seriesName, opts) {
                                return [`<div class="d-flex flex-column"><div>${props.data.labels[opts.seriesIndex]}</div><div class="text-dark-65 font-size-lg">${seriesName}</div></div>`]
                            }
                        },
                    }
                }
            ],
            // plotOptions: {
            //     donut: {
            //         hollow: {
            //             margin: 0,
            //             size: "65%"
            //         },
            //         dataLabels: {
            //             showOn: "always",
            //             name: {
            //                 show: false,
            //                 fontWeight: "700",
            //             },
            //             value: {
            //                 color: layoutProps.colorsGrayGray700,
            //                 fontSize: "30px",
            //                 fontWeight: "700",
            //                 offsetY: 12,
            //                 show: true
            //             },
            //         },
            //         track: {
            //             background: layoutProps.colorsThemeLightSuccess,
            //             strokeWidth: '100%'
            //         }
            //     }
            // },
            colors: [layoutProps.colorsThemeBasePrimary, layoutProps.colorsThemeBaseSuccess, layoutProps.colorsThemeBaseWarning, layoutProps.colorsThemeBaseDanger, "#ffbd9b", "#0a1d37", "#346751", "#5e454b", "#47597e", "#4a1c40", "#4ca1a3", "#34656d"],
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 4,
                dashArray: 0,
            },
            labels: props.data.labelDesc,
            tooltip: {
                enabled: false
            }
        };
        return options;
    }
    return (

        <div className={` pb-5 ${props.data.containerClass}`}>
            <Card>
                <Card.Body>
                    <div className="d-flex">
                        {/* <SVG className="primary-icon" src={toAbsoluteUrl(props.data.icon)} alt="" /> */}
                        <h4 className="pl-3 pt-3">
                            {props.data.title}
                        </h4>
                    </div>
                    <div className="pie-chart-container mb-5" id={props.section + props.data.id}>

                    </div>
                    <div className="font-weight-bold text-muted font-size-sm">
                        <span className="text-dark-75 font-size-h6 font-weight-bolder mr-2">{props.data.value}%</span>{props.data.precentText}</div>
                    <div className={`progress progress-xs mt-7 bg-${props.data.color}-o-60`}>
                        <div className={`progress-bar bg-${props.data.color}`} role="progressbar" style={{ width: `${props.data.value}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
export default PieChartProgressCard;