import React, { Component } from "react";
import PieChartCard from "../components/PieChartCard";
import ProgressBarStats from "./ProgressBarStats";
import { connect } from "react-redux";
// import { fetchHouseOwnerGender } from '../modules/Hamropalika/_redux/WardRedux';
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";
class StatsPieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surveyData: [],
        };
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.detail !== this.props.detail) {
            let surveyData = [
                {
                    id: 1,
                    data: nextProps.detail.selectedMotherTongue?.data.map(
                        ({ value }) => value
                    ),
                    containerClass: "col-md-6 col-lg-4",
                    labels: nextProps.detail.selectedMotherTongue?.data.map(
                        ({ value }) => value
                    ),
                    labelDesc: nextProps.detail.selectedMotherTongue?.data.map((datum) => {
                        return datum[nextProps.detail.selectedLanguage] || ''
                    }
                    ),
                    title: nextProps.detail.motherTongueList.title[nextProps.detail.selectedLanguage],
                    icon: "media/hamropalika/svg/user.svg",
                },
                {
                    id: 2,
                    data: nextProps.detail.selectedReligion?.data.map(
                        ({ value }) => value
                    ),
                    containerClass: "col-md-6 col-lg-4",
                    labels: nextProps.detail.selectedReligion?.data.map(
                        ({ value }) => value
                    ),
                    labelDesc: nextProps.detail.selectedReligion?.data.map((datum) => {
                        return datum[nextProps.detail.selectedLanguage] || ''
                    }
                    ),
                    title: nextProps.detail.religionList.title[nextProps.detail.selectedLanguage],
                    icon: "media/hamropalika/svg/user.svg",
                },
                {
                    id: 3,
                    data: nextProps.detail.selectedMarital?.data.map(({ value }) => value),
                    containerClass: "col-md-6 col-lg-4",
                    labels: nextProps.detail.selectedMarital?.data.map(
                        ({ value }) => value
                    ),
                    labelDesc: nextProps.detail.selectedMarital?.data.map((datum) => {
                        return datum[nextProps.detail.selectedLanguage] || ''
                    }
                    ),
                    title: this.props.detail.maritalList.title[nextProps.detail.selectedLanguage],
                    icon: "media/hamropalika/svg/user.svg",
                },
                {
                    id: 4,
                    data: nextProps.detail.selectedHouseOwnerGender?.data.map(
                        ({ value }) => value
                    ),
                    containerClass: "col-md-6 col-lg-4",
                    labels: nextProps.detail.selectedHouseOwnerGender?.data.map(
                        ({ value }) => value
                    ),
                    labelDesc: nextProps.detail.selectedHouseOwnerGender?.data.map((datum) => {
                        return datum[nextProps.detail.selectedLanguage] || ''
                    }
                    ),
                    title: this.props.detail.houseOwnerGenderList.title[nextProps.detail.selectedLanguage],
                    icon: "media/hamropalika/svg/user.svg",
                },
                {
                    id: 5,
                    data: nextProps.detail.selectedIsSchoolGoing?.data.map(
                        ({ value }) => value
                    ),
                    containerClass: "col-md-6 col-lg-4",
                    labels: nextProps.detail.selectedIsSchoolGoing?.data.map(
                        ({ value }) => value
                    ),
                    labelDesc: nextProps.detail.selectedIsSchoolGoing?.data.map((datum) => {
                        return datum[nextProps.detail.selectedLanguage] || ''
                    }
                    ),
                    title: this.props.detail.isSchoolGoingList.title[nextProps.detail.selectedLanguage],
                    icon: "media/hamropalika/svg/user.svg",
                },
            ];
            this.setState({ surveyData });
        }
    }
    componentDidMount() {

        let motherData = this.props.detail.selectedMotherTongue?.data.map(
            ({ value }) => value
        );
        let surveyData = [
            {
                id: 1,
                data: this.props.detail.selectedMotherTongue?.data.map(
                    ({ value }) => value
                ),
                containerClass: "col-md-6 col-lg-4",
                labels: this.props.detail.selectedMotherTongue?.data.map(
                    ({ value }) => value
                ),
                labelDesc: this.props.detail.selectedMotherTongue?.data.map((datum) => {
                    return datum[this.props.detail.selectedLanguage] || ''
                }
                ),
                title: this.props.detail.motherTongueList.title[this.props.detail.selectedLanguage],
                icon: "media/hamropalika/svg/user.svg",
            },
            {
                id: 2,
                data: this.props.detail.selectedReligion?.data.map(
                    ({ value }) => value
                ),
                containerClass: "col-md-6 col-lg-4",
                labels: this.props.detail.selectedReligion?.data.map(
                    ({ value }) => value
                ),
                labelDesc: this.props.detail.selectedReligion?.data.map((datum) => {
                    return datum[this.props.detail.selectedLanguage] || ''
                }
                ),
                title: this.props.detail.religionList.title[this.props.detail.selectedLanguage],
                icon: "media/hamropalika/svg/user.svg",
            },
            {
                id: 3,
                data: this.props.detail.selectedMarital?.data.map(({ value }) => value),
                containerClass: "col-md-6 col-lg-4",
                labels: this.props.detail.selectedMarital?.data.map(
                    ({ value }) => value
                ),
                labelDesc: this.props.detail.selectedMarital?.data.map((datum) => {
                    return datum[this.props.detail.selectedLanguage]
                }
                ),
                title: this.props.detail.maritalList.title[this.props.detail.selectedLanguage],
                icon: "media/hamropalika/svg/user.svg",
            },
            {
                id: 4,
                data: this.props.detail.selectedHouseOwnerGender?.data.map(
                    ({ value }) => value
                ),
                containerClass: "col-md-6 col-lg-4",
                labels: this.props.detail.selectedHouseOwnerGender?.data.map(
                    ({ value }) => value
                ),
                labelDesc: this.props.detail.selectedHouseOwnerGender?.data.map((datum) => {
                    return datum[this.props.detail.selectedLanguage]
                }
                ),
                title: this.props.detail.houseOwnerGenderList.title[this.props.detail.selectedLanguage],
                icon: "media/hamropalika/svg/user.svg",
            },
            {
                id: 5,
                data: this.props.detail.selectedIsSchoolGoing?.data.map(
                    ({ value }) => value
                ),
                containerClass: "col-md-6 col-lg-4",
                labels: this.props.detail.selectedIsSchoolGoing?.data.map(
                    ({ value }) => value
                ),
                labelDesc: this.props.detail.selectedIsSchoolGoing?.data.map((datum) => {
                    return datum[this.props.detail.selectedLanguage]
                }
                ),
                title: this.props.detail.isSchoolGoingList.title[this.props.detail.selectedLanguage],
                icon: "media/hamropalika/svg/user.svg",
            },
        ];
        this.setState({ surveyData });
    }
    render() {
        return (
            <>
                <div className="row" id="details">
                    {this.state.surveyData.map((datum, index) => {
                        return (
                            <PieChartCard
                                data={datum}
                                key={index}
                                section="population_pie_chart_"
                            />
                        );
                    })}
                    <ProgressBarStats />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    detail: state.ward,
});
export default connect(mapStateToProps)(StatsPieChart);
