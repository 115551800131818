import React, { useMemo, useEffect, Component } from 'react';
import { Table } from 'react-bootstrap';
import ApexCharts from "apexcharts";
import { Accordion, Button, Card } from 'react-bootstrap';
import { KTUtil } from "../../_metronic/_assets/js/components/util.js";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from 'react-redux';
import { toFormattedNumber } from '../../_metronic/_helpers/index.js';
let chartRendered = false;
const BarGraphCard = (props) => {
    const { detail } = props;
    const uiService = useHtmlClassService();
    const history = useHistory();
    const { selectedLanguage } = useSelector(
        (state) => ({ selectedLanguage: state.ward.selectedLanguage }),
        shallowEqual
    );
    const barColors = ['#2541b2', '#e5d549', '#bf1363', '#39a6a3'];
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        if (props.datum.categories) {
            const element1 = document.getElementById(props.section + props.datum.id);
            // const element = barElement;
            if (!element1) {
                return;
            }

            const height = parseInt(KTUtil.css(element1, 'height'));
            const options = getChartOptions(layoutProps, height);

            const chart = new ApexCharts(element1, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };

        }
        // renderChart(element1, layoutProps, props.datum)
    }, [props.datum]);

    function getChartOptions(layoutProps, height) {
        const options = {
            series: props.datum.values,
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
                height: 430
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom',
                    },
                }
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
                categories: props.datum.categories,
                labels: {
                    style: {
                        fontSize: '13'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '14'
                    }, formatter: function (value) {
                        return toFormattedNumber(value);
                    }

                }
            },
            colors: props.datum.values.length <= 1 ? props.datum.color : ['#2541b2', '#e5d549', '#bf1363', '#39a6a3'],
            legend: {
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                position: "top",
                horizontalAlign: "right"
            }
        };
        return options;
    }
    const getXAxisValue = () => {
        const xVal = !props.datum.xaxis ? (selectedLanguage == "nepali" ? "प्रकारहरु" : "Types") : (selectedLanguage == "nepali" ? props.datum.xaxis_nepali : props.datum.xaxis)
        return xVal;
    }
    // renderChart('${props.section}' + props.datum.id, layoutProps, props.datum)
    return (
        <div className="pb-5 col-12">
            <Card>
                <Card.Header>
                    <div className={`w-100 d-flex justify-content-${detail ? 'start' : 'between'}`} as={Button} variant="primary-light" eventKey="0">
                        {
                            detail && (
                                <NavLink to={`/home`}>
                                    <i className="fas fa-chevron-left p-5 back-icon"></i>
                                </NavLink>
                            )
                        }
                        <h3 className="p-5">
                            {props.datum.title}
                        </h3>
                        {
                            !detail && <>
                                <NavLink to={`/view-detail/${props.datum.detail}`} state={props.datum}>
                                    <button className="btn btn-light-primary font-weight-bold px-16 font-size-h6 mt-2">{selectedLanguage == "nepali" ? "विवरण हेर्नुहोस" : "View details"}</button>
                                </NavLink>
                            </>
                        }
                    </div>
                </Card.Header>
                <Card.Body className="pl-16">
                    <div className="y-axis-desc text-center">{selectedLanguage == "nepali" ? "संख्या" : "Count"}</div>
                    <div
                        id={`${props.section}${props.datum.id}`}
                        className="card-rounded-bottom"
                        style={{ height: "550px" }}
                    >
                    </div>
                    <div className="x-axis-desc text-center font-weight-bolder">{getXAxisValue()} </div>
                </Card.Body>
                {/* <Accordion.Collapse eventKey="0">
                </Accordion.Collapse> */}
            </Card>
        </div>
    );
}

export default BarGraphCard;