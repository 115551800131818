import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function Footer() {
	const today = new Date().getFullYear();
	const uiService = useHtmlClassService();
	const { currentState } = useSelector(
		(state) => ({ currentState: state.ward }),
		shallowEqual
	);
	const layoutProps = useMemo(() => {
		return {
			footerClasses: uiService.getClasses("footer", true),
			footerContainerClasses: uiService.getClasses("footer_container", true),
		};
	}, [uiService]);

	const getPartnerText = () => {
		if (currentState.selectedLanguage == "nepali") {
			return "यो पालिका प्रोफाइल मल्लरानी गाउँपालिका साझेदारी—सङ्घीयता सहयोग कार्यक्रमको सहकार्यमा तयार पारिएको हो";
		}
		return "This municipal profile has been developed in collaboration with Mallarani Rural Municipality, Sajhedari-Support to Federalism.";
	};
	return (
		<div
			className={`footer bg-white py-5 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
			id="kt_footer"
		>
			<div className="container">
				<div className="row">
					<div className="col-lg-8 text-center text-lg-left d-flex align-items-center">
						{/* <h6 className="text-primary">{getPartnerText()}</h6> */}
						{/* <img
							src={toAbsoluteUrl("media/hamropalika/logos/usaid.png")}
							alt="USAID-logo"
						/> */}
					</div>

					<div className="col-lg-4 text-center d-flex justify-content-end align-items-end">
						<h5 className="mx-5">
							©{" "}
							{currentState.selectedLanguage == "nepali"
								? "सर्बाधिकरण मल्लरानी गाउँपालिका"
								: "All rights reserved Mallarani Rural Municipality"}
						</h5>
					</div>
					{/* <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
						<img height="90px" src={toAbsoluteUrl("/media/logos/nepal_gov.png")} alt="Bhimdatta" />
						<div className="d-flex justify-content-center flex-column">
							<h3 className="text-danger">
								{currentState.selectedLanguage == "nepali"
									? "मल्लरानी गाउँपालिका"
									: "Dangisharan Rural Municipality"}
							</h3>
							<p className="text-danger">
								<strong>
									{currentState.selectedLanguage == "nepali"
										? "हेकुली, दांङ् लुम्बिनी प्रदेश, नेपाल "
										: "Hekuli, Dang Lumbini pradesh, Nepal"}
								</strong>
							</p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
}
