import React, { Component } from "react";
import PersonCard from "../components/PersonCard";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "गिरिराज पौडेल",
				nameEnglish: "Giriraj Paudel",
				designation: "गाउँपालिका प्रवक्ता",
				designationEnglish: "Spokesperson",
				email: "chandrackp77@gmail.com",
				phone: "९८६८६१३२५०",
				address: "दंगीशरण गाउँपालिका दाङ",
				addressEnglish:
					"Dangisharan rural municipality dang, lumbini pradesh, Nepal",
				image: "media/hamropalika/people/mallarani3.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "बालकृष्ण थापा",
				nameEnglish: "Bal krishna Thapa",
				designation: "प्रमुख प्रशासकीय अधिकृत",
				designationEnglish: "Chief Administrative Officer",
				email: "",
				phone: "९८५७८३८००३",
				address: "दंगीशरण गाउँपालिका दाङ, लुम्बिनी प्रदेश, नेपाल",
				addressEnglish: "Dangisharan rural municipality dang",
				image: "media/hamropalika/people/mallarani4.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "ई‍‌.सुरेश पाेख्रेल",
				nameEnglish: "Er. Suresh Pokhrel",
				designation: "सूचना अधिकारी",
				designationEnglish: "IT Officer",
				email: "",
				phone: "९८५७८३८00५",
				address: "दंगीशरण गाउँपालिका दाङ, लुम्बिनी प्रदेश, नेपाल",
				addressEnglish: "Dangisharan rural municipality dang",
				image: "media/hamropalika/people/mallarani5.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Staff;
